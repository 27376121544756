import { useState, useEffect } from 'react';
import React from 'react';
import '../index.css';
import { openInNewTab } from '../utilities';

export default function Bio() {


    const text = `
    Apart from tech in my personal time I enjoy bingeing TV shows and movies.
    some of my favourites are: The matrix series [obviously], Iron man series, death note, Lucifer.

    Playing pc games is something i enjoy
    some of my favourites are: ghost recon, valorant, NFS series.
    `;

    return (<div >
        <p className='linebreak siteFont' style={{ textAlign: 'left' }} >
            {text}
        </p>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            flexWrap: "wrap" ,
            justifyContent: 'space-around'
            
        }}>
            <button className='siteFont'  style={{ border: '2px solid green', width: 200 }}
                onClick={() => {openInNewTab('https://www.instagram.com/dharaym/')}}
            >
                Instagram →
            </button>

            
        </div>
    </div >)
}