var raininterval;
	var hideinterval;
export default function rain() {

	const canvas = document.getElementById('Matrix');
	const box = document.getElementById('box');
	box.style.opacity = 0;
	const context = canvas.getContext('2d');

	canvas.width = window.innerWidth;
	canvas.height = window.innerHeight;

	const katakana = 'アァカサタナハマヤャラワガザダバパイィキシチニヒミリヰギジヂビピウゥクスツヌフムユュルグズブヅプエェケセテネヘメレヱゲゼデベペオォコソトノホモヨョロヲゴゾドボポヴッン';
	const latin = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	const nums = '0123456789';

	const alphabet = katakana + latin + nums;

	const fontSize = 16;
	const columns = canvas.width / fontSize;

	const rainDrops = [];
	var count = 0
	for (let x = 0; x < columns; x++) {
		let value = (Math.random() - 0.8) * 100
		rainDrops[x] = value < 0 ? value : 1000;
	}
	

	const draw = () => {


		context.fillStyle = 'rgba(0, 0, 0, 0.05)';
		context.fillRect(0, 0, canvas.width, canvas.height);

		context.fillStyle = '#0F0';
		context.font = fontSize + 'px monospace';

		for (let i = 0; i < rainDrops.length; i++) {

			const text = alphabet.charAt(Math.floor(Math.random() * alphabet.length));
			context.fillText(text, i * fontSize, rainDrops[i] * fontSize);



			if (count < 100 && rainDrops[i] * fontSize > canvas.height && Math.random() > 0.975) {
				rainDrops[i] = 0;
			}
			if (count === 180) {
				clearInterval(raininterval)
				hide()
				// canvas.classList.toggle('hidden');
				
			}

			rainDrops[i]++;
		}
		count += 1
	};

	raininterval = setInterval(draw, 30);
	function hide() {
		box.style.opacity = 1
		canvas.style.opacity -= 0.1// show frame
		var opacity =  window.getComputedStyle(canvas).getPropertyValue("opacity")
		if (opacity == 0 || opacity < 0) { // check finish condition
			canvas.hidden = true

			return}
	
			setTimeout(() => {
				hide()
			}, 30);
	}

}


