import { useState, useEffect } from 'react';
import React from 'react';
import '../index.css';
import { openInNewTab } from '../utilities';

export default function Tech() {
    const date1 = new Date("2016-12-12");
    const datern = new Date("2021-09-01")

    // Get today's date
    const date2 = new Date();

    // Calculate the difference in months and years
    const diffMonths = (date2.getMonth() - date1.getMonth()) + (12 * (date2.getFullYear() - date1.getFullYear()));
    const diffYears = Math.floor(diffMonths / 12);

    const diffMonths2 = (date2.getMonth() - datern.getMonth()) + (12 * (date2.getFullYear() - datern.getFullYear()));
    const diffYears2 = Math.floor(diffMonths2 / 12);

    const text = `
    Greetings.
    I am software developer with most of my experience in mobile.
    I started my tech career in Dec 2016 giving me a total experience of ${diffYears} years and ${diffMonths % 12} months. 
    Aspiring to be Tech lead / CTO.

    In my professional capacity, i have worked on:
    - swift on server [1.5 years]
    - iOS [3 years]
    - web3: eth and algorand [2 months]
    - react native [current technology since sept 2021] [${diffYears2} years and ${diffMonths2 % 12} months]

    In my personal time i enjoy exploring cybersecurity and game development.

    `;

    const text2 = "";
    return (<div >
        <p className='linebreak siteFont' style={{ textAlign: 'left' }} >
            {text}
        </p>
        <p className='linebreak siteFont' style={{ textAlign: 'left' }} >
            I also help out with maintaining a website for a non profit:  <a href="https://www.dehrajfoundation.com/home" target="_blank">link</a>
        </p>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            flexWrap: "wrap" ,
            justifyContent: 'space-around'
            
        }}>
            <button className='siteFont'  style={{ border: '2px solid green', width: 200, margin:10 }}
                onClick={() => openInNewTab('https://www.linkedin.com/in/dharay-mistry-155710105/')}
            >
                LinkedIn →
            </button>
            <button className='siteFont'  style={{ border: '2px solid green', width: 200, margin:10 }}
                onClick={() => openInNewTab('https://github.com/dharay/resume/blob/master/R%C3%A9sum%C3%A9%20-%20Dharay.pdf')}
            >
                Resume →
            </button>
            <button className='siteFont'  style={{ border: '2px solid green', margin:10 }}
                onClick={() => openInNewTab('https://github.com/dharay/Certifications')}
            >
                Certifications →
            </button>
            
        </div>
    </div >)
}