
import './App.css';
import { useState, useEffect } from 'react';
import React from 'react';
import TabView from './tabs/TabView';
import Bio from './tabs/Bio';
import Tech from './tabs/Tech';

import rain from './rain';

export default function App() {
  const tabs = [
    { label: 'Tech', content: <Tech/>},
    { label: 'Bio', content: <Bio/> },
    
  ];


  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    document.body.style.backgroundColor = "black"
    rain()
    document.title = 'Dharay';
  });
  return (
    <>
      <div id='box' style={{ opacity: 1, zIndex: 2, position: "absolute", display: 'flex', flexDirection:'column', padding:10}} >
        
        <p className='siteFont title' style={{fontSize:50, paddingTop:20}}>DHARAY</p>
        <p className='siteFont title' style={{fontSize:20,paddingBottom:20}}>Software developer</p>
        <div style={{flexDirection:'column', justifyContent:'space-around',width:'100%'}}>
        <TabView tabs={tabs} />
        </div>
        
      </div>

      <canvas id="Matrix" style={{ zIndex: 4 , opacity:1}} ></canvas>
    </>

  );

}
