
import { useState } from 'react';
 import '../index.css'; 
import './tabview.css'


function TabView({ tabs }) {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="tab-view" style={{padding:15, marginRight: 15}}>
      <div className="tab-buttons" style={{ position:'relative'}} >
        <div style={{border:'2px solid #0f0',borderTop:'0', position:'absolute', width:200, height:'20%', alignSelf:'center',bottom:0,left:'0',right:'0', marginLeft:'auto',marginRight:'auto'}}></div>
        {tabs.map((tab, index) => (
          <button
          key={index}
          onClick={() => setActiveTab(index)}
          className='siteFont ' 
          style={{
              margin:10,
            fontWeight: activeTab === index ? 'bold' : 'normal',
            borderBottom: activeTab === index ? '2px solid green' : ' none',
            borderTop: activeTab === index ? '2px solid green' : 'none',
            borderLeft: activeTab === index ? '2px solid green' : '1px solid green',
            borderRight: activeTab === index ? '2px solid green' : '1px solid green',
          
          }}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="tab-content">
        {tabs.map((tab, index) => (
          <div
            key={index}
            style={{ position: 'relative'}}
            className={`tab-pane ${index === activeTab ? 'active' : ''}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TabView;
